@import url("https:fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2 !important;
  /* scrollbar-width: 5px !important; */
}
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background-color: rgba(15, 12, 12, 0.253);
  border-radius: 50px;
  /* outline: 1px solid slategrey; */
}
code {
  font-family: "Poppins", sans-serif !important;
}
button:hover {
  opacity: 0.9 !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.settingBoxA:hover .settingBoxAhoverEffect {
  transform: scale(1);
  transition: all 0.5s ease;
  height: auto;
}
.cardBox:hover .iconhoverEffect {
  transform: scale(1);
}
.settingBoxA:hover {
  background-color: #f2f2f2 !important;
  border-radius: 8px;
}

/* .css-1mzzq05-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
} */
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.01) !important;
}
.form-control {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #c0c0c0;
}
